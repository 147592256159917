import React from "react";
import { Markdown } from "../components/Markdown";

const ResourcesPage = () => {
    return (
        <>
            <Markdown file={"resources"} />
        </>
    );
};

export default ResourcesPage;
